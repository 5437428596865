import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Book, Lock, Cookie, HelpCircle } from "lucide-react";
import CommonHeader from "../../components/CommonHeader";
import CommonFooter from "../../components/CommonFooter";
import { config } from "../../config";
import { Mixpanel } from "../../utils/Analytics";

const Legal = () => {
  const location = useLocation();
  const [currentView, setCurrentView] = useState("terms");
  const [brandName] = useState(config.labels.brandName);
  const [effectiveDate] = useState("01/30/2025");
  const [privacyEmail] = useState(config.emails.teamEmail);
  const [supportEmail] = useState(config.emails.supportEmail);
  const [supportEmailLink] = useState(
    `mailto:${config.emails.supportEmail}?subject=Support%20Request&body=Please be very clear about your issue so our team can swiftly solve it!`
  );

  const legalList = ["terms", "privacy", "cookies", "FAQ"];

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.view && typeof parsedQuery.view === "string") {
      setCurrentView(parsedQuery.view.toLowerCase());
    }

    Mixpanel.track("Legal Page View", {
      currentView: currentView,
    });
  }, [location.search, currentView]);

  const renderLegalList = () => (
    <div className="bg-white/80 backdrop-blur-md p-6 rounded-lg shadow-lg mb-8">
      <ul className="flex flex-wrap justify-center gap-4">
        {legalList.map((view, key) => {
          const isActive = currentView === view.toLowerCase();
          return (
            <li key={key}>
              <button
                onClick={() => setCurrentView(view.toLowerCase())}
                className={`px-4 py-2 rounded-md transition-colors ${
                  isActive
                    ? "bg-red-600 text-white"
                    : "bg-gray-200 text-gray-900 hover:bg-gray-300"
                }`}
              >
                {view.toUpperCase()}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const renderTerms = () => (
    <div className="space-y-4 text-gray-900">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Book className="mr-2 text-red-600" size={24} />
        Terms and Conditions
      </h2>
      <p className="font-medium">
        Terms and Conditions [Effective Date: {effectiveDate}]
      </p>
      <p>
        <strong>Introduction:</strong> Welcome to {brandName}. These Terms and
        Conditions ("Terms") govern your access to and use of our website and
        services ("Website"), which facilitate AI-powered multi-platform ad
        campaign management and optimization. By accessing or using our Website,
        you agree to be bound by these Terms and all applicable laws.
      </p>
      <p>
        <strong>Services Description:</strong> {brandName} provides advanced
        software for generating, managing, and optimizing digital advertising
        campaigns across multiple platforms including Google Ads, Facebook Ads,
        YouTube Ads, Instagram Ads, TikTok Ads, LinkedIn Ads, Snapchat Ads, X
        Ads, and Reddit Ads. Our services include AI-powered ICP research, ad
        strategy generation, and campaign optimization.
      </p>
      <p>
        <strong>User Accounts:</strong> Users must register and create an
        account to use {brandName}. Users agree to provide accurate, current,
        and complete information during the registration process, including
        business and advertising data. Users are responsible for maintaining the
        confidentiality of account credentials and are fully responsible for all
        activities that occur under their account.
      </p>
      <p>
        <strong>Data Management:</strong> {brandName} facilitates the
        organization and analysis of advertising data across multiple platforms.
        Users are responsible for the accuracy of the data they provide to the
        platform. {brandName} provides tools for AI-powered ICP research and
        digital ad strategy generation, but users should consult with qualified
        marketing professionals for specific business advice.
      </p>
      <p>
        <strong>Intellectual Property:</strong> All content provided on the
        Website, including but not limited to AI agents, optimization
        algorithms, analytics tools, and interfaces, is the property of{" "}
        {brandName} or its content suppliers and protected by intellectual
        property laws. Users retain ownership of their advertising data but
        grant {brandName} necessary rights to process and analyze this data to
        provide the services.
      </p>
      <p>
        <strong>Prohibited Activities:</strong> Users agree not to engage in
        activities that violate any laws, infringe upon the rights of others, or
        breach any terms outlined in these Terms. This includes attempting to
        gain unauthorized access to or disrupt any parts of the Website, sharing
        false advertising data, or using the service for any illegal or
        unauthorized purpose.
      </p>
      <p>
        <strong>Key Features:</strong> {brandName} offers the following key
        features to enhance advertising performance:
      </p>
      <ul className="list-disc space-y-2 pl-5">
        <li>
          <strong>ICP Research Agent:</strong> AI-powered analysis of business
          goals and industry trends
        </li>
        <li>
          <strong>Ad Strategy Agent:</strong> Generate complete multi-platform
          strategies with targeting parameters
        </li>
      </ul>
      <p>
        Users agree to use these features responsibly and understand that the
        platform is not a substitute for professional marketing expertise.
      </p>
      <p>
        <strong>Service Tiers and Payment:</strong> {brandName} offers the
        following subscription plans:
      </p>
      <ul className="list-disc space-y-2 pl-5">
        <li>Starter: $499/month - 2 platforms, up to $50k monthly ad spend</li>
        <li>Growth: $999/month - 4 platforms, up to $150k monthly ad spend</li>
        <li>Scale: $1,999/month - All platforms, unlimited ad spend</li>
        {/* <li>Enterprise: Custom pricing with advanced features</li> */}
      </ul>
      <p>
        All plans include a 14-day free trial. Users are responsible for
        canceling their subscription before the trial period ends if they do not
        wish to continue using the service.
      </p>
      <p>
        <strong>Performance Disclaimers:</strong> While {brandName} strives to
        optimize ad performance and reduce costs, we cannot guarantee specific
        results such as ROAS improvements or cost reductions. Examples of
        results (e.g., "65% Lower Ad Spend" or "~2.8x ROAS Increase") are based
        on actual customer data but may not be representative of all users'
        experiences.
      </p>
      <p>
        <strong>Referral Program:</strong> Participation in the Referral program
        is subject to additional terms and conditions provided separately.
        Referral benefits are subject to change and may be discontinued at any
        time.
      </p>
      <p>
        <strong>Amendments to Terms:</strong> {brandName} reserves the right to
        amend these Terms at any time. All amendments to these Terms will be
        posted on the Website and effective immediately upon posting.
      </p>
      <p>
        <strong>Termination:</strong> {brandName} may terminate your access to
        all or any part of the Website at any time, with or without cause, with
        or without notice, effective immediately.
      </p>
      <p>
        <strong>Governing Law:</strong> These Terms shall be governed by and
        construed in accordance with the laws of the jurisdiction in which{" "}
        {brandName} operates, without regard to its conflict of law provisions.
      </p>
      <p>
        <strong>Contact Information:</strong> If you have any questions about
        these Terms or the {brandName} service, please contact us at{" "}
        <a href={supportEmailLink} target="_blank" rel="noreferrer">
          {supportEmail}
        </a>
        .
      </p>
    </div>
  );

  const renderPrivacy = () => (
    <div className="space-y-4 text-gray-900">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Lock className="mr-2 text-red-600" size={24} />
        Privacy Policy
      </h2>
      <p className="font-medium">
        Privacy Policy [Effective Date: {effectiveDate}]
      </p>
      <p>
        <strong>Information We Collect:</strong>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Business Information:</strong> Company name, website,
            industry, size, revenue range, and business goals collected during
            account registration or service usage.
          </li>
          <li>
            <strong>Advertising Account Data:</strong> Campaign data, ad
            performance metrics, audience insights, and conversion tracking from
            integrated ad platforms.
          </li>
          <li>
            <strong>Financial Information:</strong> Subscription details, ad
            spend budgets, and payment processing data for our services.
          </li>
          <li>
            <strong>Usage Analytics:</strong> Service interaction patterns,
            feature utilization, and optimization preferences.
          </li>
          <li>
            <strong>Technical Data:</strong> IP address, browser type, device
            information, and platform usage analytics.
          </li>
          <li>
            <strong>Communication Data:</strong> Support interactions, feedback,
            and service-related communications.
          </li>
        </ul>
      </p>
      <p>
        <strong>How We Use Your Information:</strong>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Service Provision:</strong> To create AI-powered marketing
            strategies, optimize ad campaigns, and provide cross-platform
            analytics.
          </li>
          <li>
            <strong>Performance Optimization:</strong> To analyze advertising
            patterns, improve ROAS, and enhance our AI-powered recommendations.
          </li>
          <li>
            <strong>Campaign Management:</strong> To monitor ad performance,
            generate insights, and provide automated optimization.
          </li>
          <li>
            <strong>Service Enhancement:</strong> To develop new features,
            improve optimization algorithms, and refine ad strategies.
          </li>
          <li>
            <strong>Communication:</strong> To send performance updates,
            optimization alerts, and relevant service information.
          </li>
        </ul>
      </p>
      <p>
        <strong>Sharing Your Information:</strong>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Service Providers:</strong> We share information with
            trusted third-party services that help us operate, including cloud
            storage, analytics, and security providers.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose information
            when required by law or to protect rights and prevent fraud.
          </li>
          <li>
            <strong>Business Transfers:</strong> In case of merger, acquisition,
            or asset sale, your information may be transferred while maintaining
            the same level of protection.
          </li>
        </ul>
      </p>
      <p>
        <strong>Data Security Measures:</strong>
        <ul className="list-disc pl-5 space-y-2">
          <li>End-to-end encryption of advertising and business data</li>
          <li>Secure API integration protocols</li>
        </ul>
      </p>
      <p>
        <strong>Your Data Rights:</strong> You have specific rights regarding
        your business and advertising information:
        <ul className="list-disc pl-5 space-y-2">
          <li>Request detailed advertising data export</li>
          <li>Manage platform integration permissions</li>
          <li>Review account access logs</li>
          <li>Set granular privacy preferences</li>
        </ul>
      </p>
      <p>
        <strong>Data Retention:</strong> We retain your business and advertising
        data as long as necessary to provide services or as required by law. You
        can request data deletion at any time, subject to legal requirements.
      </p>
      <p>
        <strong>Third-Party Platforms:</strong> Our service integrates with
        various advertising platforms, each with their own privacy policies.
        Users should review these policies when connecting their advertising
        accounts.
      </p>
      <p>
        <strong>Changes to Privacy Policy:</strong> We will notify you of any
        significant changes to how we treat business and advertising data.
        Changes will not apply retroactively to previously collected
        information.
      </p>
      <p>
        <strong>Contact Us:</strong> For privacy-related inquiries or to
        exercise your rights regarding advertising data, please contact our
        Privacy Officer at {privacyEmail}.
      </p>
    </div>
  );

  const renderCookies = () => (
    <div className="space-y-4 text-gray-900">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <Cookie className="mr-2 text-red-600" size={24} />
        Cookie Policy
      </h2>
      <p className="font-medium">
        Cookie Policy [Effective Date: {effectiveDate}]
      </p>
      <p className="font-medium">Cookie Policy</p>
      <p>
        <strong>Introduction:</strong> This Cookie Policy ("Policy") outlines
        how {brandName}
        ("we," "us," or "our") utilizes cookies and similar tracking
        technologies on our website ("Website"). These technologies help us
        deliver AI-powered advertising analytics and campaign optimization
        services. Your use of the Website signifies your consent to our use of
        cookies in accordance with this Policy.
      </p>
      <p>
        <strong>What are Cookies?</strong> Cookies are small data files stored
        on your device when you visit websites. They help us remember your
        preferences, enhance your advertising management experience, and collect
        information about your interactions with our platform to provide
        personalized optimization services.
      </p>
      <p>
        <strong>How We Use Cookies:</strong>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Essential Cookies:</strong> These are necessary for the
            Website's core functionality, including:
            <ul className="list-disc pl-5 mt-2">
              <li>User authentication and secure login</li>
            </ul>
          </li>
          <li>
            <strong>Targeting Cookies:</strong> Used to personalize your
            experience:
            <ul className="list-disc pl-5 mt-2">
              <li>Custom optimization strategies</li>
              <li>Platform-specific preferences</li>
              <li>Industry-relevant insights</li>
              <li>Performance benchmarks</li>
            </ul>
          </li>
        </ul>
      </p>
      <p>
        <strong>Third-Party Cookies:</strong> We partner with trusted providers
        who may set cookies for:
        <ul className="list-disc pl-5 space-y-2">
          <li>Advertising platform integrations</li>
          <li>Analytics services</li>
          <li>Performance tracking</li>
          <li>Security services</li>
          <li>Feature support</li>
        </ul>
      </p>
      <p>
        <strong>Cookie Security and Ad Data:</strong> For cookies storing
        advertising information:
        <ul className="list-disc pl-5 space-y-2">
          <li>Enhanced encryption for platform credentials</li>
          <li>Strict access controls for ad data</li>
          <li>Regular security audits</li>
          <li>Compliance with advertising regulations</li>
        </ul>
      </p>
      <p>
        <strong>Managing Cookies:</strong> You can control cookie preferences
        through:
        <ul className="list-disc pl-5 space-y-2">
          <li>Browser settings configuration</li>
          <li>Platform preference center</li>
          <li>Privacy dashboard controls</li>
          <li>Individual feature opt-outs</li>
        </ul>
        Note that declining certain cookies may impact the full functionality of
        our advertising optimization and analytics features.
      </p>
      <p>
        <strong>Data Retention:</strong> Cookies related to advertising data are
        retained according to:
        <ul className="list-disc pl-5 space-y-2">
          <li>Platform integration requirements</li>
          <li>Your account preferences</li>
          <li>Technical necessity for optimization</li>
          <li>Privacy best practices</li>
        </ul>
      </p>
      <p>
        <strong>Changes to This Policy:</strong> We reserve the right to update
        this Cookie Policy. Changes will be posted here and are effective
        immediately. Significant changes to ad data handling will be
        communicated directly to users.
      </p>
      <p>
        <strong>Contact Us:</strong> For questions about our cookie practices or
        this Policy, especially regarding advertising data handling, please
        contact us at{" "}
        <a href={supportEmailLink} target="_blank" rel="noreferrer">
          {supportEmail}
        </a>
        .
      </p>
    </div>
  );

  const renderFAQ = () => (
    <div className="space-y-4 text-gray-900">
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <HelpCircle className="mr-2 text-red-600" size={24} />
        Frequently Asked Questions
      </h2>
      <div className="FAQContentContainer space-y-6">
        <div className="FAQContent">
          <div className="FAQQuestion font-semibold">What is {brandName}?</div>
          <div className="FAQAnswer mt-2 text-gray-700">
            {brandName} is an AI-powered advertising platform that helps
            businesses generate, manage, and optimize multi-platform ad
            campaigns without expensive agencies or large teams. Our platform
            helps you:
            <ul className="mt-2 list-disc pl-5">
              <li>Cut digital ad spend by up to 65%</li>
              <li>Achieve Higher ROAS on average</li>
              <li>Manage campaigns across all major ad platforms</li>
              <li>Get AI-powered optimization recommendations</li>
            </ul>
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion font-semibold">
            What platforms does {brandName} support?
          </div>
          <div className="FAQAnswer mt-2 text-gray-700">
            Our platform integrates with all major advertising channels:
            <ul className="mt-2 list-disc pl-5">
              <li>
                <strong>Social Media Ads:</strong>
                <ul className="pl-5 mt-1">
                  <li>Facebook Ads</li>
                  <li>Instagram Ads</li>
                  <li>LinkedIn Ads</li>
                  <li>TikTok Ads</li>
                  <li>Snapchat Ads</li>
                  <li>X Ads (formerly Twitter)</li>
                </ul>
              </li>
              <li>
                <strong>Search & Display:</strong>
                <ul className="pl-5 mt-1">
                  <li>Google Ads</li>
                  <li>YouTube Ads</li>
                  <li>Reddit Ads</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion font-semibold">
            How do the AI agents work?
          </div>
          <div className="FAQAnswer mt-2 text-gray-700">
            Our platform features two core AI agents:
            <ul className="mt-2 list-disc pl-5">
              <li>
                <strong>ICP Research Agent:</strong> Analyzes your business
                goals and industry trends to create detailed ideal customer
                profiles and identify high-potential market segments
              </li>
              <li>
                <strong>Ad Strategy Agent:</strong> Generates complete
                multi-platform strategies with targeting parameters and budget
                recommendations for each platform
              </li>
            </ul>
            These agents work together to optimize your advertising performance
            and reduce costs across all platforms.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion font-semibold">
            What are the subscription plans?
          </div>
          <div className="FAQAnswer mt-2 text-gray-700">
            <strong>Starter Plan ($499/month):</strong>
            <ul className="mt-2 list-disc pl-5">
              <li>2 platforms</li>
              <li>$50k monthly ad spend</li>
              <li>Basic optimization</li>
              <li>Email support</li>
            </ul>
            <strong className="mt-4 block">Growth Plan ($999/month):</strong>
            <ul className="mt-2 list-disc pl-5">
              <li>4 platforms</li>
              <li>$150k monthly ad spend</li>
              <li>Advanced optimization</li>
              <li>Priority support</li>
            </ul>
            <strong className="mt-4 block">Scale Plan ($1,999/month):</strong>
            <ul className="mt-2 list-disc pl-5">
              <li>All platforms</li>
              <li>Unlimited ad spend</li>
              <li>Custom strategies</li>
              <li>Dedicated success manager</li>
            </ul>
            All plans include a 14-day free trial and no long-term contract.
          </div>
        </div>

        <div className="FAQContent">
          <div className="FAQQuestion font-semibold">
            How can I get support?
          </div>
          <div className="FAQAnswer mt-2 text-gray-700">
            We offer comprehensive customer support:
            <ul className="mt-2 list-disc pl-5">
              <li>
                Email support at{" "}
                <a href={supportEmailLink} target="_blank" rel="noreferrer">
                  {supportEmail}
                </a>
              </li>
              <li>In-app chat support for quick queries</li>
              <li>Platform documentation and guides</li>
              <li>Regular product webinars</li>
              <li>Dedicated account managers (Scale plan)</li>
            </ul>
            Our support team is committed to helping you maximize your
            advertising ROI with {brandName}.
          </div>
        </div>
      </div>
    </div>
  );

  const getLegalContent = () => {
    switch (currentView) {
      case "terms":
        return renderTerms();
      case "privacy":
        return renderPrivacy();
      case "cookies":
        return renderCookies();
      case "faq":
        return renderFAQ();
      default:
        return renderTerms();
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-slate-100 to-gray-100 text-gray-900 flex flex-col">
      <CommonHeader />

      <main className="flex-grow container mx-auto px-4 py-24">
        <h1 className="text-3xl font-bold mb-8">Legal Information (WIP)</h1>

        {renderLegalList()}

        <div className="bg-white/80 backdrop-blur-md rounded-lg p-6 shadow-lg">
          {getLegalContent()}
        </div>
      </main>

      <CommonFooter />
    </div>
  );
};

export default Legal;
