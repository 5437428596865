import { Shield } from "lucide-react";
import { Link } from "react-router-dom";
import { config } from "../../config";

const CommonFooter = () => {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-6 sm:gap-8">
          <div className="flex items-center gap-2">
            <Shield className="h-8 w-8 text-black" />
            <Link to="/" className="text-2xl font-bold text-black">
              {config.labels.brandName}
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-4 text-center sm:text-left">
            <p className="text-sm text-gray-600">
              © {new Date().getFullYear()} {config.labels.brandName}. All rights
              reserved.
            </p>
            <div className="hidden sm:block text-gray-300">|</div>

            <a
              href="mailto:sales@adinsights.ai?subject=Referral Program Application&body=Hi, I'd like to join the referral program.%0D%0A%0D%0APlease find my details below:%0D%0A%0D%0AEmail: %0D%0ASocial Media Profiles: %0D%0A%0D%0AThank you!"
              className="text-sm text-gray-600 hover:text-red-600 transition-colors duration-200"
            >
              Join our Referral Program
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CommonFooter;
