export const config = {
  cookies: {
    consentCookieLabel:
      process.env.REACT_APP_CONSENT_COOKIE_LABEL || "consent-cookie",
  },
  emails: {
    supportEmail:
      process.env.REACT_APP_SUPPORT_EMAIL || "support@adinsights.ai",
    teamEmail: process.env.REACT_APP_SUPPORT_EMAIL || "team@adinsights.ai",
  },
  mixpanel: {
    token: process.env.REACT_APP_MP_TOKEN || "token",
  },
  labels: {
    brandName: process.env.REACT_APP_BRAND_NAME || "AdInsights.ai",
  },
  links: {
    blog: "https://medium.com/@adinsightsai",
    legal: "/legal",
    refer:
      "mailto:sales@adinsights.ai?subject=Referral Program Application&body=Hi, I'd like to join the referral program.%0D%0A%0D%0APlease find my details below:%0D%0A%0D%0AEmail: %0D%0ASocial Media Profiles: %0D%0A%0D%0AThank you!",
  },
  typeform: {
    waitlistForm:
      process.env.REACT_APP_WAITLIST_FORM ||
      "https://f4q4dmiym0t.typeform.com/to/CNuyn2cA",
  },
  videos: {
    demoSource: "https://s3.us-east-1.amazonaws.com/adinsights.ai/demo.mp4",
    demoPoster: "demo-poster.webp",
  },
  pricing: {
    enterpriceMailTo:
      "mailto:sales@adinsights.ai?subject=Enterprise Plan Inquiry&body=Hi AdInsights.ai team,%0D%0A%0D%0AI'm interested in learning more about your Enterprise plan. Here are our company details:%0D%0A%0D%0ACompany Name:%0D%0AMonthly Ad Spend:%0D%0ANumber of Platforms:%0D%0ATeam Size:%0D%0A%0D%0AThank you!",
  },
};
