import React, { useState } from "react";
import { Calculator, DollarSign, HelpCircle, X, Check } from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "../../config";

const CostCalculator = () => {
  const [currentSpend, setCurrentSpend] = useState("");
  const [currentSetup, setCurrentSetup] = useState("agency");
  const [showGuide, setShowGuide] = useState(false);

  const calculateCosts = () => {
    const spend = parseFloat(currentSpend) || 0;

    // Calculate management costs
    const managementCosts = {
      agency: {
        base: 8000,
        percentage: spend * 0.15,
        total: 8000 + spend * 0.15,
      },
      inHouse: {
        base: 14000,
        total: 14000,
      },
      adInsights: {
        base:
          spend <= 50000
            ? 499.99 // Starter Plan
            : spend <= 150000
            ? 999.99 // Growth Plan
            : spend <= 300000
            ? 1499.99 // Pro Plan
            : 1999.99, // Scale Plan
        total:
          spend <= 50000
            ? 499.99 // Starter Plan
            : spend <= 150000
            ? 999.99 // Growth Plan
            : spend <= 300000
            ? 1499.99 // Pro Plan
            : 1999.99, // Scale Plan
      },
    };

    // Calculate total costs (ad spend + management)
    const totalCosts = {
      agency: spend + managementCosts.agency.total,
      inHouse: spend + managementCosts.inHouse.total,
      adInsights: spend + managementCosts.adInsights.total,
    };

    return {
      spend,
      management: managementCosts,
      total: totalCosts,
    };
  };

  const costs: any = calculateCosts();
  const savings = costs.total[currentSetup] - costs.total.adInsights;

  const Guide = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full relative">
        <button
          onClick={() => setShowGuide(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-5 w-5" />
        </button>

        <h3 className="text-xl font-bold mb-4">
          How to Use the Cost Calculator
        </h3>

        <div className="space-y-4">
          <div>
            <h4 className="font-semibold mb-2">
              Step 1: Enter Your Monthly Ad Spend
            </h4>
            <p className="text-gray-600">
              Enter the total amount you spend on ads across all platforms
              monthly.
            </p>
          </div>

          <div>
            <h4 className="font-semibold mb-2">
              Step 2: Select Your Current Setup
            </h4>
            <p className="text-gray-600">Choose between:</p>
            <ul className="list-disc ml-6 text-gray-600">
              <li>Agency (15% of ad spend + $8K/mo retainer)</li>
              <li>In-house team ($14K/mo fixed cost)</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-2">Understanding Your Costs</h4>
            <ul className="list-disc ml-6 text-gray-600">
              <li>Traditional Agency: Base retainer + % of ad spend</li>
              <li>In-house Team: Fixed monthly cost</li>
              <li>AdInsights.ai: Tiered pricing based on ad spend</li>
            </ul>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Example Calculation</h4>
            <p className="text-gray-600">For $20,000 monthly ad spend:</p>
            <ul className="text-sm text-gray-600 space-y-1">
              <li>Agency: $20,000 + ($8,000 + 15% fee) = $31,000 total</li>
              <li>AdInsights.ai: $20,000 + $499 = $20,499 total</li>
              <li>Monthly Savings: $10,501</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <div className="flex justify-between items-center mb-6">
        <h4 className="text-lg font-semibold flex items-center gap-2">
          <Calculator className="h-5 w-5 text-red-600" />
          Cost Savings Calculator
        </h4>
        <button
          onClick={() => setShowGuide(true)}
          className="text-gray-500 hover:text-gray-700"
        >
          <HelpCircle className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-6">
        {/* Ad Spend Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Your Current Monthly Ad Spend
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
            <input
              type="number"
              value={currentSpend}
              onChange={(e) => setCurrentSpend(e.target.value)}
              className="pl-8 w-full p-2 border border-gray-300 rounded"
              placeholder="Enter amount"
            />
          </div>
        </div>

        {/* Setup Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Your Current Setup
          </label>
          <div className="flex gap-4">
            <button
              onClick={() => setCurrentSetup("agency")}
              className={`px-4 py-2 text-sm rounded-lg transition-all ${
                currentSetup === "agency"
                  ? "bg-red-600 text-white"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            >
              Agency
            </button>
            <button
              onClick={() => setCurrentSetup("inHouse")}
              className={`px-4 py-2 text-sm rounded-lg transition-all ${
                currentSetup === "inHouse"
                  ? "bg-red-600 text-white"
                  : "bg-gray-100 text-gray-600 hover:bg-gray-200"
              }`}
            >
              In-House Team
            </button>
          </div>
        </div>

        {/* Results */}
        {parseFloat(currentSpend) > 0 && (
          <div className="space-y-4 border-t pt-4">
            {/* Cost Breakdown */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
              {/* Current Costs Card */}
              <div className="p-3 bg-gray-50 rounded">
                <div className="text-gray-600 mb-2 font-medium">
                  Current Monthly Cost
                </div>
                <div className="space-y-2">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Ad Spend:</span>
                    <span className="font-mono text-right">
                      ${costs.spend.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Management:</span>
                    <span className="font-mono text-right break-all">
                      ${costs.management[currentSetup].total.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between font-semibold pt-2 border-t">
                    <span>Total:</span>
                    <span className="font-mono text-right break-all">
                      ${costs.total[currentSetup].toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>

              {/* AdInsights Costs Card */}
              <div className="p-3 bg-gray-50 rounded">
                <div className="text-gray-600 mb-2 font-medium">
                  AdInsights.ai Cost
                </div>
                <div className="space-y-2">
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Ad Spend:</span>
                    <span className="font-mono text-right">
                      ${costs.spend.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between">
                    <span className="text-gray-600">Platform:</span>
                    <span className="font-mono text-right break-all">
                      ${costs.management.adInsights.total.toLocaleString()}
                    </span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between font-semibold pt-2 border-t">
                    <span>Total:</span>
                    <span className="font-mono text-red-600 text-right break-all">
                      ${costs.total.adInsights.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Monthly Savings */}
            <div className="bg-gray-900 text-white p-4 rounded">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
                <div>
                  <div className="text-lg font-bold">Monthly Savings</div>
                  <div className="text-sm text-gray-300">
                    {((savings / costs.total[currentSetup]) * 100).toFixed(0)}%
                    cost reduction
                  </div>
                </div>
                <div className="text-2xl font-bold font-mono w-full sm:w-auto text-right break-all">
                  ${savings.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showGuide && <Guide />}
    </div>
  );
};

const EnhancedPricingSection = () => {
  const [showCalculator, setShowCalculator] = useState(false);

  const handlePlanClick = (isEnterprise: boolean | any) => {
    if (process.env.NODE_ENV === "production") {
      mixpanel.track(isEnterprise ? "Enterprise Contact" : "Plan Selected");
    }
  };

  const pricingPlans = [
    {
      name: "Starter",
      price: "$499.99",
      features: [
        "All platforms",
        "0-$50k monthly ad spend",
        "Basic customer support",
        "Up to 6 projects created",
        "Up to 6 ICPs generated monthly",
        "Up to 4 ad strategies generated monthly",
        "Up to 4 campaign insights reports monthly",
        "Up to 2 competitor analysis reports monthly",
        "Platform-specific insights",
        "Replaces $7K+/mo in agency costs",
        "AI Model: OpenAI (default)",
        "Upgrade to advanced models for additional fees:",
        "- Anthropic: +$100/month",
        "- DeepSeek: +$50/month",
        "- Gemini: +$75/month",
        "Up to 50 AI Q&A queries/month",
      ],
    },
    {
      name: "Growth",
      price: "$999.99",
      highlighted: true,
      features: [
        "All platforms",
        "50$-150k+ monthly ad spend",
        "Priority customer support",
        "Up to 10 projects created",
        "Up to 10 ICPs generated monthly",
        "Up to 25 ad strategies generated monthly",
        "Up to 10 campaign insights reports monthly",
        "Up to 6 competitor analysis reports monthly",
        "Cross-platform insights",
        "Replaces $12K+/mo in agency costs",
        "AI Model: OpenAI (default)",
        "Upgrade to advanced models for additional fees:",
        "- Anthropic: +$100/month",
        "- DeepSeek: +$50/month",
        "- Gemini: +$75/month",
        "Up to 200 AI Q&A queries/month",
      ],
      isEnterprise: false,
    },
    {
      name: "Pro",
      price: "$1,499.99",
      features: [
        "All platforms",
        "$150k-$300k monthly ad spend",
        "Priority customer support",
        "Up to 25 projects created",
        "Up to 50 ICPs generated monthly",
        "Up to 50 ad strategies generated monthly",
        "Up to 25 campaign insights reports monthly",
        "Up to 12 competitor analysis reports monthly",
        "Cross-platform insights",
        "Replaces $18K+/mo in agency costs",
        "AI Model: OpenAI (default)",
        "Upgrade to advanced models for additional fees:",
        "- Anthropic: +$100/month",
        "- DeepSeek: +$50/month",
        "- Gemini: +$75/month",
        "Up to 500 AI Q&A queries/month",
      ],
      isEnterprise: false,
    },
    {
      name: "Scale",
      price: "$1,999.99/month",
      features: [
        "All platforms",
        "Unlimited ad spend",
        "Dedicated success manager",
        "Unlimited projects created",
        "Up to 100 ICPs generated monthly",
        "Up to 100 ad strategies generated monthly",
        "Unlimited campaign insights reports",
        "Unlimited competitor analysis reports",
        "Cross-platform insights",
        "Replaces $22K+/mo in agency costs",
        "AI Model: OpenAI (default)",
        "Upgrade to advanced models for additional fees:",
        "- Anthropic: +$100/month",
        "- DeepSeek: +$50/month",
        "- Gemini: +$75/month",
        "Unlimited AI Q&A queries",
      ],
      isEnterprise: true,
    },
  ];

  return (
    <section className="mb-20">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-bold mb-4">Simple, Transparent Pricing</h2>
        <p className="text-xl text-gray-600 mb-8">
          Replace $120K+ Annual Costs with AI-Driven Optimization
        </p>
        <p className="text-gray-600">
          Our clients save an average of $9,750/month compared to agency
          retainers
          <br />
          and $14,200/month vs in-house teams (salaries + tools + training)
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto mb-12">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className={`bg-white p-8 flex flex-col h-full ${
              plan.highlighted
                ? "border-2 border-red-600"
                : plan.isEnterprise
                ? "border-2 border-gray-900"
                : "border border-gray-200"
            }`}
          >
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">{plan.name}</h3>
              <p className="text-3xl font-bold">
                {plan.price}
                <span className="text-base font-normal text-gray-600">
                  {!plan.isEnterprise && "/month"}
                </span>
              </p>
              {plan.name === "Scale" && (
                <p className="text-sm text-red-600 mt-2">
                  Replaces $22K+/mo in agency costs
                </p>
              )}
            </div>

            <div className="flex-grow">
              <ul className="space-y-4">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-center gap-2">
                    <Check className="h-5 w-5 text-black flex-shrink-0" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            {plan.isEnterprise && (
              <p className="text-sm text-gray-500 mt-4 mb-4">
                For context, enterprise agencies typically charge $15K–$50K/mo
                for comparable services - our AI-driven platform delivers
                superior insights at 80% lower cost
              </p>
            )}

            <div className="mt-8">
              <a
                href={
                  plan.isEnterprise
                    ? config.pricing.enterpriceMailTo
                    : config.typeform.waitlistForm
                }
                target="_blank"
                rel="noreferrer"
                onClick={() => handlePlanClick(plan.isEnterprise)}
                className={`block w-full text-center px-6 py-3 font-medium transition-all duration-300 ${
                  plan.highlighted
                    ? "bg-red-600 text-white hover:bg-red-700"
                    : plan.isEnterprise
                    ? "bg-gray-900 text-white hover:bg-black"
                    : "bg-black text-white hover:bg-gray-900"
                }`}
              >
                {plan.isEnterprise ? "Contact Sales" : "Request Demo"}
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className="max-w-3xl mx-auto">
        {!showCalculator ? (
          <button
            onClick={() => setShowCalculator(true)}
            className="flex items-center gap-2 mx-auto text-red-600 font-medium"
          >
            <Calculator className="h-5 w-5" />
            Show Cost Savings Calculator
          </button>
        ) : (
          <CostCalculator />
        )}
      </div>

      <div className="text-center mt-12">
        <p className="text-gray-600 mb-4">
          All plans include: 7-day free trial • No long-term contract
        </p>
      </div>
    </section>
  );
};

export default EnhancedPricingSection;
