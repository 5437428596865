import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import CookieConsentBanner from "../../components/CookieConsentBanner";
import { removeAnalyticCookies } from "../../components/CookieConsentBanner";
import { config } from "../../config";

import Legal from "../Legal";
import PageNotFound from "../PageNotFound";
import App from "../App";

// Route paths
export const homeRoutePath = "/";
export const legalRoutePath = "/legal";

const CoreRouter = () => {
  const handleProtectedRoutes = (Component: any, props: any) => {
    const consentCookie = Cookies.get(config.cookies.consentCookieLabel);
    if (consentCookie && consentCookie === "false") {
      removeAnalyticCookies();
    }
    return <Component {...props} />;
  };

  return (
    <Router>
      <div className="main">
        <Routes>
          {/* Basic Routes */}
          <Route
            path={homeRoutePath}
            Component={(props: any): any => handleProtectedRoutes(App, props)}
          />
          <Route
            path={`${legalRoutePath}/*`}
            Component={(props: any): any => handleProtectedRoutes(Legal, props)}
          />
          <Route
            path="*"
            Component={(props: any): any =>
              handleProtectedRoutes(PageNotFound, props)
            }
          />
        </Routes>
        <CookieConsentBanner />
      </div>
    </Router>
  );
};

export default CoreRouter;
