import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { X, Menu, Shield } from "lucide-react";
import { config } from "../../config";

// Constants for navigation
const navItems = [
  { name: "Blog", href: config.links.blog, external: true },
  { name: "Refer", href: config.links.refer, external: true },
  { name: "Legal", href: config.links.legal, external: false },
];

// Header Component
const CommonHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-md" : ""
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center gap-2">
          <Shield className="h-8 w-8 text-black" />
          <Link to="/" className="text-2xl font-bold text-black">
            AdInsights.ai
          </Link>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex flex-row items-center gap-4">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              target={item.external ? "_blank" : "_self"}
              className="text-black px-3 py-2 text-sm font-medium hover:text-red-600 transition-colors duration-200"
            >
              {item.name === "Refer" ? "Referral Program" : item.name}
            </Link>
          ))}
        </div>

        {/* Mobile menu button and navigation container */}
        <div className="lg:hidden flex items-center gap-2">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 rounded-md text-black hover:text-red-600 focus:outline-none"
          >
            <span className="sr-only">Open menu</span>
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="lg:hidden border-t border-gray-200 bg-white"
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  target={item.external ? "_blank" : "_self"}
                  className="block px-3 py-2 text-base font-medium text-black hover:text-red-600 transition-colors duration-200"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name === "Refer" ? "Referral Program" : item.name}
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default CommonHeader;
