import { useState, useEffect } from "react";
import {
  Target,
  Check,
  Database,
  Bot,
  TrendingUp,
  DollarSign,
  BarChart,
  Award,
  Mail,
  Zap,
  PieChart,
  RefreshCw,
  X,
  SendHorizonal,
  BrainCircuit,
  Rocket,
  Sparkles,
  Brain,
  FileSpreadsheet,
  Shield,
  Settings,
  GitCompare,
  Share2,
  Users,
  MessageCircle,
  Calendar,
  ArrowRight,
  Briefcase,
  Code,
} from "lucide-react";
import mixpanel from "mixpanel-browser";
import { config } from "../../config";
import CommonFooter from "../../components/CommonFooter";
import CommonHeader from "../../components/CommonHeader";
import EnhancedPricingSection from "../../components/EnhancedPricingSection";

const PlatformGrid = () => {
  const platforms = [
    { name: "Google Ads", icon: Database }, // ~$180B
    { name: "Facebook Ads", icon: Target }, // ~$75B
    { name: "YouTube Ads", icon: Mail }, // ~$30B
    { name: "Instagram Ads", icon: PieChart }, // ~$25B
    { name: "TikTok Ads", icon: Zap }, // ~$15B
    { name: "LinkedIn Ads", icon: Bot }, // ~$4B
    { name: "Snapchat Ads", icon: BarChart }, // ~$4B
    { name: "X Ads", icon: SendHorizonal }, // ~$3B
    { name: "Reddit Ads", icon: RefreshCw }, // ~$0.5B
  ];

  return (
    <div className="grid grid-cols-3 md:grid-cols-3 gap-6 max-w-4xl mx-auto mt-12">
      {platforms.map((platform, index) => (
        <div key={index} className="flex flex-col items-center gap-3 group">
          <div className="w-16 h-16 border border-gray-200 flex items-center justify-center transition-all duration-300 group-hover:border-red-600">
            <platform.icon className="w-8 h-8 text-black group-hover:text-red-600 transition-all duration-300" />
          </div>
          <span className="text-sm font-medium text-gray-700">
            {platform.name}
          </span>
        </div>
      ))}
    </div>
  );
};

const MarketingStrategiesSection = () => {
  const strategies = [
    {
      name: "Influencer Marketing",
      icon: Users,
      description:
        "Generate data-driven influencer marketing plans optimized for your industry",
    },
    {
      name: "Affiliate Programs",
      icon: Share2,
      description:
        "Create commission structures and partner programs based on market data",
    },
    {
      name: "Growth Framework",
      icon: Rocket,
      description:
        "Generate comprehensive growth strategies using proven patterns",
    },
  ];

  return (
    <div className="mb-20">
      <h2 className="text-3xl font-bold text-center mb-4">
        Beyond Ads:{" "}
        <span className="text-red-600">AI-Generated Marketing Strategy</span>
      </h2>
      <p className="text-center text-gray-600 mb-8 max-w-3xl mx-auto">
        Our AI agents analyze your business data to create comprehensive
        marketing strategies across modern channels - going beyond traditional
        advertising
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
        {strategies.map((strategy, index) => (
          <div key={index} className="flex flex-col items-center group">
            <div className="w-16 h-16 border border-gray-200 flex items-center justify-center mb-3 transition-all duration-300 group-hover:border-red-600">
              <strategy.icon className="w-8 h-8 text-black group-hover:text-red-600 transition-all duration-300" />
            </div>

            <span className="text-lg font-medium text-gray-700 mb-2">
              {strategy.name}
            </span>

            <p className="text-sm text-gray-600 text-center mb-4">
              {strategy.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const EarlyAccessSection = () => {
  return (
    <div className="bg-black text-white p-12 my-20">
      <div className="max-w-4xl mx-auto text-center">
        <div className="inline-block bg-red-600/10 text-red-500 px-4 py-2 rounded-full text-sm font-medium mb-6">
          Founder's Circle - Early Access Program
        </div>

        <h2 className="text-4xl font-bold mb-6">
          Work Directly With Google & Coinbase Engineers
        </h2>

        <p className="text-xl text-gray-300 mb-8">
          Get direct access to our founding team of engineers from Google and
          Coinbase. Skip the typical customer service layers - work directly
          with the builders who scaled user acquisition for trillion-dollar tech
          companies.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <div className="p-6 border border-gray-800 rounded-lg bg-gray-900/50">
            <Briefcase className="h-8 w-8 text-red-500 mx-auto mb-4" />
            <div className="text-lg font-semibold mb-2">Industry Veterans</div>
            <div className="text-gray-400">
              12+ years scaling growth at Google, Coinbase & launching viral
              apps
            </div>
          </div>

          <div className="p-6 border border-gray-800 rounded-lg bg-gray-900/50">
            <Code className="h-8 w-8 text-red-500 mx-auto mb-4" />
            <div className="text-lg font-semibold mb-2">
              Technical Expertise
            </div>
            <div className="text-gray-400">
              Built acquisition systems processing $100M+ in ad spend
            </div>
          </div>

          <div className="p-6 border border-gray-800 rounded-lg bg-gray-900/50">
            <Award className="h-8 w-8 text-red-500 mx-auto mb-4" />
            <div className="text-lg font-semibold mb-2">Proven Results</div>
            <div className="text-gray-400">
              Launched apps reaching 90+ countries
            </div>
          </div>
        </div>

        <div className="p-8 border border-gray-800 rounded-lg bg-gray-900/50 mb-12">
          <h3 className="text-2xl font-bold mb-6">
            Exclusive Early Access Benefits
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center">
              <MessageCircle className="h-8 w-8 text-red-500 mb-4" />
              <div className="text-lg font-semibold mb-2">
                Direct Founder Access
              </div>
              <div className="text-gray-400">
                Private Slack channel with our founding engineers
              </div>
            </div>

            <div className="flex flex-col items-center">
              <Calendar className="h-8 w-8 text-red-500 mb-4" />
              <div className="text-lg font-semibold mb-2">
                Strategy Sessions
              </div>
              <div className="text-gray-400">
                Weekly 1:1 calls with engineers
              </div>
            </div>

            <div className="flex flex-col items-center">
              <Users className="h-8 w-8 text-red-500 mb-4" />
              <div className="text-lg font-semibold mb-2">VIP Treatment</div>
              <div className="text-gray-400">
                Shape our roadmap, get features built for you
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-2xl mx-auto">
          <div className="flex flex-col items-center gap-8">
            <div className="flex items-center gap-2 text-xl">
              <span className="font-semibold">
                Elite access limited to 20 companies
              </span>
              <ArrowRight className="h-5 w-5" />
              <span className="text-red-500 font-bold">8 spots remaining</span>
            </div>

            <div className="space-y-4">
              <a
                href={config.typeform.waitlistForm}
                target="_blank"
                rel="noreferrer"
                onClick={(_: any) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Final CTA Clicked");
                  }
                }}
                className="inline-block bg-red-600 text-white px-8 py-4 rounded-lg font-medium hover:bg-red-700 transition-all duration-300"
              >
                Join the Founder's Circle
              </a>
              <p className="text-sm text-gray-400">
                Limited time offer • Direct access to engineers • Premium
                support included
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function LandingPage() {
  const [activeTab, setActiveTab] = useState<any>("research");
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const selectedTabImageDescription = () => {
    switch (activeTab) {
      case "research":
        return "The ICP research agent analyzes your business data to generate detailed ideal customer profiles - identifying target industries, key decision makers, and conversion timelines. Status tracking and filtering help manage multiple ICPs across your projects.";
      case "strategy":
        return "The ad strategy agent creates comprehensive multi-platform campaign strategies with detailed budgets, targeting, and performance metrics. View platform-specific recommendations across Google, Facebook, LinkedIn and more with projected ROI metrics.";
      case "insights":
        return "The AI insights agent analyzes campaign performance across platforms to identify winning patterns and optimization opportunities. Get platform-specific findings with confidence scores and clear recommended actions to improve results.";
      case "analysis":
        return "The competitive analysis agent performs in-depth competitor analysis using your business data and market insights to identify strategic advantages. Track competitive positioning, discover market opportunities, and get actionable recommendations for differentiation.";
      default:
        return "";
    }
  };

  useEffect(() => {
    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV !== "production",
      ignore_dnt: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
    });
  }, []);

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <CommonHeader />

      {/** Hero Section */}
      <main className="pt-24">
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center mb-16">
            <div className="flex justify-center gap-2 mb-6">
              <Award className="h-6 w-6 text-red-600" />
              <span className="text-sm font-medium text-red-600">
                Featured in top AI directories
              </span>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 text-black">
              Turn Campaign Data Into Growth
            </h1>
            <p className="text-2xl text-gray-600 mb-6 max-w-3xl mx-auto">
              AI-powered platform that helps businesses generate, manage, and
              optimize multi-platform ad campaigns without expensive agencies or
              large teams
            </p>
            <p className="text-xl text-gray-600 mb-6 max-w-3xl mx-auto">
              An Amazing Product + Strategic Execution = 📈ROAS
            </p>

            <div className="flex flex-col md:flex-row justify-center items-center gap-8 mb-12">
              <div className="flex items-center gap-2">
                <DollarSign className="h-6 w-6 text-red-600" />
                <span className="text-lg text-gray-600">Huge Cost Savings</span>
              </div>
              <div className="flex items-center gap-2">
                <TrendingUp className="h-6 w-6 text-red-600" />
                <span className="text-lg text-gray-600">Higher ROAS</span>
              </div>
              <div className="flex items-center gap-2">
                <Database className="h-6 w-6 text-red-600" />
                <span className="text-lg text-gray-600">Instant Setup</span>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 justify-center">
              <a
                href={config.typeform.waitlistForm}
                target="_blank"
                rel="noreferrer"
                onClick={(_: any) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Demo Request Clicked");
                  }
                }}
                className="inline-block bg-black text-white px-8 py-4 text-lg font-medium hover:bg-gray-900 transition-all duration-300"
              >
                Request Demo
              </a>
            </div>
          </div>

          {/* Platform Grid */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-8">
              One Platform,{" "}
              <span className="text-red-600">All Your Ad Channels</span>
            </h2>
            <PlatformGrid />
          </div>

          {/* Marketing Strategies */}
          <MarketingStrategiesSection />

          {/* Demo */}
          <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
            <h2 className="text-4xl font-bold text-center mb-6 text-black">
              See Our AI Agents in Action
            </h2>
            <p className="text-center text-gray-600 italic mb-4">
              Watch how our platform automates campaign creation and
              optimization across multiple ad platforms
            </p>
            <p className="text-center text-sm text-gray-500 mb-8">
              Our platform and AI agents are continuously evolving. We regularly
              update this demo to showcase the latest features and capabilities.
            </p>
            <div className="relative aspect-video border border-gray-200 overflow-hidden">
              <video
                controls
                id="demo-video"
                className="w-full h-full fit-cover"
                src={config.videos.demoSource}
                poster={process.env.PUBLIC_URL + config.videos.demoPoster}
                onClick={(_) => {
                  if (process.env.NODE_ENV === "production") {
                    mixpanel.track("Video Player Clicked");
                    (window as any).heap.track("Video Player Clicked");
                  }
                }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </section>

          {/* Industry Focus */}
          <div className="bg-white border border-gray-200 p-8 relative overflow-hidden mb-20">
            <div className="absolute top-0 left-0 w-full h-2 bg-red-600"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h2 className="text-3xl font-semibold mb-6 text-black">
                  Perfect for Digital Advertisers:
                </h2>
                <ul className="space-y-6">
                  {[
                    "E-commerce Businesses ($50k-$500k+ ad spend)",
                    "D2C Brands",
                    "SaaS Companies",
                    "Digital Marketing Teams",
                    "SMB Service Providers",
                    "Growth-Stage Startups",
                    "Marketing Agencies",
                    "Marketing Teams Seeking AI Support",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0 w-8 h-8 bg-red-600 flex items-center justify-center mr-4">
                        <Check className="h-5 w-5 text-white" />
                      </div>
                      <span className="text-lg text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative w-full">
                <div className="bg-gray-50 border border-gray-200 p-2 sm:p-4">
                  <div className="flex flex-wrap gap-2 mb-4">
                    <button
                      onClick={() => setActiveTab("research")}
                      className={`px-3 py-2 sm:px-4 text-sm font-medium rounded-md transition-colors ${
                        activeTab === "research"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black hover:bg-gray-100"
                      }`}
                    >
                      Research
                    </button>
                    <button
                      onClick={() => setActiveTab("strategy")}
                      className={`px-3 py-2 sm:px-4 text-sm font-medium rounded-md transition-colors ${
                        activeTab === "strategy"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black hover:bg-gray-100"
                      }`}
                    >
                      Strategy
                    </button>
                    <button
                      onClick={() => setActiveTab("insights")}
                      className={`px-3 py-2 sm:px-4 text-sm font-medium rounded-md transition-colors ${
                        activeTab === "insights"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black hover:bg-gray-100"
                      }`}
                    >
                      Insights
                    </button>
                    <button
                      onClick={() => setActiveTab("analysis")}
                      className={`px-3 py-2 sm:px-4 text-sm font-medium rounded-md transition-colors ${
                        activeTab === "analysis"
                          ? "bg-red-600 text-white"
                          : "text-gray-600 hover:text-black hover:bg-gray-100"
                      }`}
                    >
                      Analysis
                    </button>
                  </div>
                  <div>
                    <div
                      className="aspect-video bg-white border border-gray-200 rounded-lg overflow-hidden cursor-pointer"
                      onClick={() =>
                        setSelectedImage(`${activeTab}-example.webp`)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + `${activeTab}-example.webp`
                        }
                        alt={`${activeTab} screenshot`}
                        className="w-full h-full object-contain"
                      />
                    </div>

                    {selectedImage && (
                      <div
                        className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
                        onClick={() => setSelectedImage(null)}
                      >
                        <button
                          className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedImage(null);
                          }}
                        >
                          <X className="h-6 w-6" />
                        </button>
                        <img
                          src={process.env.PUBLIC_URL + selectedImage}
                          alt="Full size view"
                          className="max-w-full max-h-[90vh] object-contain rounded-lg"
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-4 text-sm text-gray-600 p-2">
                    {selectedTabImageDescription()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Case Studies */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Real Results from Real Businesses
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  name: "D2C Beauty Brand",
                  metric: "Higher ROAS",
                  quote:
                    "AI identified winning UGC patterns and reallocated budget to top-performing channels",
                },
                {
                  name: "SaaS Company",
                  metric: "45% CAC Reduction",
                  quote:
                    "Cross-platform insights revealed hidden opportunities in channel mix",
                },
                {
                  name: "E-commerce Platform",
                  metric: "3x Conversion Rate",
                  quote:
                    "Pattern analysis helped optimize targeting and creative strategy",
                },
              ].map((study, index) => (
                <div
                  key={index}
                  className="bg-white p-8 border border-gray-200"
                >
                  <h3 className="text-xl font-semibold mb-4 text-red-600">
                    {study.metric}
                  </h3>
                  <p className="text-gray-600">{study.quote}</p>
                </div>
              ))}
            </div>
          </div>

          {/* How It Works */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Powered by <span className="text-red-600">AI Agents</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  icon: Target,
                  title: "ICP Research Agent",
                  text: "Analyzes your business goals and industry trends to create detailed ideal customer profiles and identify high-potential market segments",
                },
                {
                  icon: Bot,
                  title: "Ad Strategy Agent",
                  text: "Generates complete multi-platform strategies with targeting parameters and budget recommendations for each platform",
                },
                {
                  icon: BarChart,
                  title: "AI Insights Agent",
                  text: "Discover patterns across platforms and receive actionable recommendations",
                },
                {
                  icon: GitCompare,
                  title: "Competitive Analysis Agent",
                  text: "Performs in-depth competitor analysis using your business data, ICP insights, and competitor information to identify market opportunities and strategic advantages",
                },
              ].map((step, index) => (
                <div
                  key={index}
                  className="bg-white p-8 border border-gray-200 hover:border-red-600 transition-colors"
                >
                  <div className="mb-4">
                    <step.icon className="h-12 w-12 text-black mx-auto" />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-center">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 text-center">{step.text}</p>
                </div>
              ))}
            </div>
          </div>

          {/* AI Q&A Feature Section */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Ask AI About Your{" "}
              <span className="text-red-600">Marketing Data</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-7xl mx-auto px-4">
              <div>
                <h3 className="text-2xl font-semibold mb-6">
                  Your Marketing Brain, On-Demand
                </h3>

                <div className="space-y-8">
                  {[
                    {
                      title: "ICP Research Q&A",
                      examples: [
                        "What industries showed highest LTV in our ICP analysis?",
                        "Which customer segments have the shortest sales cycles?",
                        "What are the common pain points across our top ICPs?",
                      ],
                    },
                    {
                      title: "Ad Strategy Intelligence",
                      examples: [
                        "Why did you recommend increasing Facebook's budget by 40%?",
                        "What's the reasoning behind the TikTok targeting parameters?",
                        "Compare the strategy differences between Facebook and Instagram",
                      ],
                    },
                    {
                      title: "Performance Insights",
                      examples: [
                        "Which creatives are driving the highest ROAS?",
                        "Why did you suggest reallocating budget from LinkedIn to TikTok?",
                        "What patterns did you find in our top-performing campaigns?",
                      ],
                    },
                    {
                      title: "Competitive Analysis",
                      examples: [
                        "What are our key differentiators vs Competitor X?",
                        "Which competitor tactics should we counter first?",
                        "Where are the biggest market opportunities vs competitors?",
                      ],
                    },
                  ].map((category, index) => (
                    <div key={index} className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-4 flex items-center gap-2">
                        <Brain className="h-5 w-5 text-red-600" />
                        {category.title}
                      </h4>
                      <ul className="space-y-3">
                        {category.examples.map((example, i) => (
                          <li key={i} className="flex items-start gap-3">
                            <span className="text-sm py-1 px-3 bg-white border border-gray-200 rounded-full">
                              {example}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <div className="sticky top-8">
                  <div className="bg-black text-white p-8 rounded-lg mb-8">
                    <h4 className="text-xl font-semibold mb-4">
                      Perfect For Every Marketing Role
                    </h4>

                    <div className="space-y-6">
                      <div>
                        <h5 className="font-medium mb-2">E-commerce & D2C</h5>
                        <p className="text-gray-300 text-sm">
                          Understand customer segments better and optimize
                          multi-channel strategy for maximum ROAS
                        </p>
                      </div>

                      <div>
                        <h5 className="font-medium mb-2">Marketing Agencies</h5>
                        <p className="text-gray-300 text-sm">
                          Quick answers about client strategies and performance
                          to improve reporting and decision-making
                        </p>
                      </div>

                      <div>
                        <h5 className="font-medium mb-2">
                          SaaS & Growth Teams
                        </h5>
                        <p className="text-gray-300 text-sm">
                          Deep insights into B2B targeting and competitive
                          positioning across channels
                        </p>
                      </div>

                      <div>
                        <h5 className="font-medium mb-2">Marketing Leaders</h5>
                        <p className="text-gray-300 text-sm">
                          Strategic understanding of budget allocation and
                          performance patterns
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-900 p-8 rounded-lg">
                    <div className="flex items-center gap-3 mb-4">
                      <Brain className="h-8 w-8 text-red-600" />
                      <div>
                        <h4 className="text-white font-semibold">
                          Contextual Understanding
                        </h4>
                        <p className="text-sm text-gray-400">
                          AI retains full context of your data
                        </p>
                      </div>
                    </div>

                    <div className="space-y-4 text-sm text-gray-300">
                      <p>
                        • Understands relationships between ICPs and strategies
                      </p>
                      <p>• Connects insights across multiple campaigns</p>
                      <p>• Links competitive analysis to strategic decisions</p>
                      <p>• Provides data-backed recommendations</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* How is AdInsights.ai different? */}
          <div className="bg-black text-white p-12 mb-20">
            <h2 className="text-4xl font-bold text-center mb-12">
              How is AdInsights.ai different?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
              <div>
                <h3 className="text-2xl font-semibold mb-6">
                  How AdInsights.ai Outperforms Generic AI Tools
                </h3>

                <div className="space-y-8">
                  <div className="flex gap-4">
                    <Brain className="h-6 w-6 flex-shrink-0 text-red-600" />
                    <div>
                      <h4 className="font-semibold mb-2">
                        Strategy Over Creativity
                      </h4>
                      <p className="text-gray-300">
                        Unlike AI tools that only generate ads, we focus on the
                        strategic foundation of who to target, how to allocate
                        budgets, and what's working across platforms.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <FileSpreadsheet className="h-6 w-6 flex-shrink-0 text-red-600" />
                    <div>
                      <h4 className="font-semibold mb-2">
                        Built on Real Campaign Data
                      </h4>
                      <p className="text-gray-300">
                        Available models analyzed thousands of anonymized
                        campaigns, providing the power of predictive budget
                        allocation and adaptive benchmarks specific to your
                        industry.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <Shield className="h-6 w-6 flex-shrink-0 text-red-600" />
                    <div>
                      <h4 className="font-semibold mb-2">
                        Your Data, Your Control
                      </h4>
                      <p className="text-gray-300">
                        Export campaign reports from any platform. Our agents
                        analyze your historical performance and industry trends
                        to build a strategy unique to you.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <Settings className="h-6 w-6 flex-shrink-0 text-red-600" />
                    <div>
                      <h4 className="font-semibold mb-2">
                        Flexible Model Selection
                      </h4>
                      <p className="text-gray-300">
                        Choose the AI model that best fits your needs and
                        budget. From cost-efficient OpenAI models to advanced
                        Anthropic, customize your experience while maintaining
                        high-quality insights.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <Brain className="h-6 w-6 flex-shrink-0 text-red-600" />
                    <div>
                      <h4 className="font-semibold mb-2">
                        Interactive Document Intelligence
                      </h4>
                      <p className="text-gray-300">
                        Ask questions about your ICPs, strategies, insights, and
                        competitive analysis reports. Our AI understands context
                        and provides actionable answers to help you make better
                        decisions faster.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <div className="relative w-full max-w-md">
                  <div className="absolute inset-0 bg-red-600 opacity-10 rounded-full"></div>
                  <div className="relative bg-gray-900 rounded-xl p-8">
                    <h4 className="font-semibold mb-4 flex items-center gap-2">
                      <RefreshCw className="h-5 w-5" />
                      The Insights Flywheel
                    </h4>
                    <div className="space-y-4 text-sm">
                      <div className="flex items-center gap-3">
                        <div className="h-8 w-8 rounded-full bg-red-600 flex items-center justify-center">
                          1
                        </div>
                        <p>ICP Research identifies ideal targets</p>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-8 w-8 rounded-full bg-red-600 flex items-center justify-center">
                          2
                        </div>
                        <p>Ad Strategy optimizes channel mix</p>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-8 w-8 rounded-full bg-red-600 flex items-center justify-center">
                          3
                        </div>
                        <p>Campaign data feeds insights</p>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="h-8 w-8 rounded-full bg-red-600 flex items-center justify-center">
                          4
                        </div>
                        <p>AI models improve with each cycle</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Model Flexibility */}
          <div className="mb-20">
            <h2 className="text-3xl font-bold text-center mb-12">
              Flexible{" "}
              <span className="text-red-600">AI Model Infrastructure</span>
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-7xl mx-auto px-4">
              {[
                {
                  icon: BrainCircuit,
                  title: "OpenAI",
                  text: "Cost-efficient processing of complex marketing data with 128k context windows for comprehensive campaign analysis and ICP development at 60% lower cost",
                },
                {
                  icon: Bot,
                  title: "Anthropic",
                  text: "Advanced reasoning for strategic ad targeting and real-time performance analysis with collaborative artifact generation for campaign optimization",
                },
                {
                  icon: Rocket,
                  title: "DeepSeek",
                  text: "Efficient processing of vast campaign datasets with 5x cost savings, enabling precise audience segmentation and advanced performance forecasting",
                },
                {
                  icon: Sparkles,
                  title: "Gemini",
                  text: "Native cross-platform creative analysis and optimization, enhancing ad performance through unified processing of text, image, and video content",
                },
              ].map((model, index) => (
                <div
                  key={index}
                  className="bg-white p-6 border border-gray-200 rounded-lg hover:border-red-600 transition-colors"
                >
                  <div className="mb-4">
                    <model.icon className="h-12 w-12 text-black mx-auto" />
                  </div>
                  <h3 className="text-xl font-semibold mb-4 text-center">
                    {model.title}
                  </h3>
                  <p className="text-gray-600 text-center">{model.text}</p>
                </div>
              ))}
            </div>

            <p className="text-center text-gray-500 mt-8">
              Additional models coming soon
            </p>
          </div>

          {/* Pricing */}
          <EnhancedPricingSection />

          {/* Early Access */}
          <EarlyAccessSection />
        </section>
      </main>

      <CommonFooter />
    </div>
  );
}

export default LandingPage;
