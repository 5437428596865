import React from "react";
import { Link } from "react-router-dom";
import { Home, ArrowLeft } from "lucide-react";
import { motion } from "framer-motion";
import { config } from "../../config";

const PageNotFound = () => {
  // Animation variants for staggered fade-in effect
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-slate-100 to-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <motion.div
        className="max-w-md w-full space-y-8 text-center"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants}>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            404 - Page Not Found
          </h2>
          <p className="mt-2 text-center text-xl text-gray-600">
            Oops! The page you're looking for doesn't exist.
          </p>
        </motion.div>

        <motion.div variants={itemVariants} className="mt-8 space-y-6">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gradient-to-br from-slate-50 via-slate-100 to-gray-100 text-gray-500">
                What would you like to do?
              </span>
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <Link
              to="/"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-700 hover:bg-blue-800 md:py-4 md:text-lg md:px-10 transition-all duration-300 shadow-md hover:shadow-lg"
            >
              <Home className="w-5 h-5 mr-2" />
              Go to Home
            </Link>
            <button
              onClick={() => window.history.back()}
              className="w-full flex items-center justify-center px-8 py-3 border border-gray-300 text-base font-medium rounded-lg text-blue-700 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 transition-all duration-300 shadow-sm hover:shadow-md"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Go Back
            </button>
          </div>
        </motion.div>

        <motion.p
          variants={itemVariants}
          className="mt-2 text-center text-sm text-gray-600"
        >
          If you believe this is an error, please{" "}
          <Link
            to={`mailto://${config.emails.supportEmail}`}
            className="font-medium text-blue-700 hover:text-blue-800 transition-colors duration-300"
          >
            contact our support team
          </Link>
          .
        </motion.p>
      </motion.div>
    </div>
  );
};

export default PageNotFound;
